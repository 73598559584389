import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaEnvelope, FaPhone } from 'react-icons/fa';

const Footer = () => {
    return (
        <div className="header  bottom-0 w-full p-5 text-center">
            <div className="flex justify-center space-x-5 mb-4">
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className=" hover:text-blue-500">
                    <FaFacebook size={24} />
                </a>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className=" hover:text-pink-500">
                    <FaInstagram size={24} />
                </a>
            </div>
            <div className="flex justify-center space-x-3">
                <div className="flex items-center space-x-2 whitespace-nowrap">
                    <FaEnvelope />
                    <span>consultas@dgb-training.com</span>
                </div>
                <div className="flex items-center space-x-2 whitespace-nowrap">
                    <FaPhone />
                    <span>(223) 528-1777</span>
                </div>
            </div>
        </div>
    );
};

export default Footer;
