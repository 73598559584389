import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import "../styles.css"

const Navbar = () => {
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Ajusta este valor según tus necesidades
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };
    const logo = "/images/logo.png"
    if (isMobile) {
        return (// fixed
            <nav className=" top-0 w-full header rounded-b shadow-md">
                    <div className="container mx-auto p-4 flex items-center justify-between">
                        <img src={process.env.PUBLIC_URL + logo} alt={logo} className="w-20 h-20 rounded-lg " />
                        <button
                            type="button"
                            className="text-black hover:text-gray-300 focus:outline-none"
                            onClick={toggleNavbar}
                        >
                            {isOpen ? <FaTimes className="h-6 w-6 fill-current" /> : <FaBars className="h-6 w-6 fill-current" />}

                        </button>
                    </div>
                    {isOpen && (
                        <div className=" flex flex-col items-center">
                            <Link
                                to="/"
                                smooth={true}
                                duration={500}
                                className="block text-xl text-black font-light py-2 mt-2 hover:text-gray-300 cursor-pointer"
                                onClick={toggleNavbar}
                            >
                                Inicio
                            </Link>
                            <Link
                                to="/about"
                                smooth={true}
                                duration={500}
                                className="block text-xl text-black font-light py-2 mt-2 hover:text-black-300 cursor-pointer"
                                onClick={toggleNavbar}
                            >
                                Casos Exitosos
                            </Link>
                            <Link
                                to="/history"
                                smooth={true}
                                duration={500}
                                className="block text-xl text-black font-light py-2 mt-2 hover:text-gray-300 cursor-pointer"
                                onClick={toggleNavbar}
                            >
                                Historia
                            </Link>
                            <Link
                                to="/services"
                                smooth={true}
                                duration={500}
                                className="block text-xl text-black font-light py-2 mt-2 hover:text-gray-300 cursor-pointer"
                                onClick={toggleNavbar}
                            >
                                Planes
                            </Link>
                            <Link
                                to="/contact"
                                smooth={true}
                                duration={500}
                                className="block text-xl text-black font-light py-2 mt-2 hover:text-gray-300 cursor-pointer"
                                onClick={toggleNavbar}
                            >
                                Contacto
                            </Link>
                        </div>
                    )}

            </nav>
        );
    }

    
    return (
        <nav className="header p-4 shadow-md">
            <div className="container mx-auto flex justify-between items-center">
                <div className="text-black text-lg font-semibold">
                <img src={process.env.PUBLIC_URL + logo} alt={logo} className="w-20 h-20" />
                </div>
                <div className="space-x-4 text-black">
                    <Link to="/">Inicio</Link>
                    <Link to="/about">Casos Exitosos</Link>
                    <Link to="/history">Historia</Link>
                    <Link to="/services">Planes</Link>
                    <Link to="/contact">Contacto</Link>
                </div>
            </div>
        </nav>
    );


};

export default Navbar;
