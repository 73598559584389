import React from "react";

const History = () => {
  return (
    <div>
      <h1 className="historia">Historia</h1>
      <div className="texto-historia text-center sm:text-left p-4">
        <p>
          DGB TRAINING TU RUTINA PERSONAL está formada por un grupo
          interdisciplinario y cuenta con distintos especialistas en el área de
          entrenamiento, nutrición, psicología, antropometría, recreación y
          rehabilitación, entre otras áreas. Su creador el Profesor de Educación
          Física Diego González Bas, coordina el desarrollo de la actividad y
          arma los equipos de trabajo, acorde a las necesidades de cada persona,
          su experiencia en el rubro como Preparador físico, entrenador y
          formador de Profesores, te garantiza una solución a lo que estas
          buscando.
        </p>
        <br />
        <p>
          Nuestra propuesta se extiende a personas amateurs, atletas de alto
          rendimiento en distintas disciplinas, adultos mayores, niños y toda
          persona que quiera mejorar su condición física, entendiendo que es un
          proceso que depende de varios factores en los que trabajaremos juntos.
          Nuestro objetivo es que puedas alcanzar tu mejor versión y rendimiento
          con un seguimiento totalmente personalizado y con resultados
          garantizados que te permitan cumplir tus objetivos y sumar nuevos
          hábitos saludables que van a mejorar tu calidad de vida.
        </p>
        <br />
        <p>
          Nuestro equipo de entrenamiento personalizado cuenta con más de 10
          años de experiencia en el rubro del Fitness y con la visión de ofrecer
          una experiencia de entrenamiento única y personalizada para cada uno
          de nuestros clientes. Desde entonces, hemos ayudado a cientos de
          personas a alcanzar sus objetivos de salud y bienestar y rendimiento
          físico.
        </p>
        <br />
        <p>
          En nuestros inicios, comenzamos con un pequeño equipo de entrenadores
          altamente capacitados y motivados que trabajaban juntos para ofrecer
          programas de entrenamiento personalizados para cada uno de nuestros
          clientes. Nuestro enfoque en la personalización nos permitió adaptar
          nuestros programas a las necesidades individuales de nuestros
          clientes, lo que nos permitió obtener resultados notables.
        </p>
        <br />
        <p>
          A medida que fuimos creciendo, también lo hizo nuestra reputación como
          líderes en el campo del entrenamiento personalizado. DGB TRAINING te
          ofrece un seguimiento 100% ONLINE, con rutinas novedosas, prácticas y
          con el asesoramiento y seguimiento a cada alumno en su desarrollo y
          evolución. Ademas tenemos algunas opciones con entrenadores
          presenciales (consultar por esta opción). También hemos sido
          reconocidos por nuestro enfoque innovador y efectivo para el
          entrenamiento y presentados en varios medios de comunicación
          importantes.
        </p>
        <br />
        <p>
          Hoy en día, seguimos comprometidos con nuestra visión original de
          ofrecer una experiencia de entrenamiento personalizada y única para
          cada uno de nuestros clientes. A medida que avanzamos hacia el futuro,
          esperamos seguir ayudando a las personas a alcanzar sus objetivos de
          salud y bienestar a través de nuestro enfoque personalizado y
          altamente efectivo.
        </p>
        <br />
      </div>
    </div>
  );
};

export default History;
