import React from "react";
import ImageSlider from "../components/Slider";

const About = () => {
  const images = [
    process.env.PUBLIC_URL + "/images/casoExito1.jpg",
    process.env.PUBLIC_URL + "/images/casoExito2.jpg",
    process.env.PUBLIC_URL + "/images/CasoExito3.jpg",
  ];

  const images2 = [
    process.env.PUBLIC_URL + "/images/casoExito4.jpg",
    process.env.PUBLIC_URL + "/images/CasoExito5.jpg",
    process.env.PUBLIC_URL + "/images/casoExito6.jpg",
  ];

  const homeClassname = "rounded-lg my-4  md:w-3/4 mx-auto";

  return (
    <div className="mx-8">
      <h1 className="quienes-somos">Casos Exitosos</h1>
      <div className="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-lg my-4">
        <div>
          <h2 className="text-lg font-customPlans text-center m-4 md:pb-10">
            AGUSTINA (18) ciudad de Mar del Plata
          </h2>
          <p className="texto-planes">
            Alumna personalizada con seguimiento de 3 días semanales, plan de
            entrenamiento acorde a lo hablado en las entrevistas previas en base
            a sus objetivos personales. Además, se le realizo un plan de
            alimentación para que pueda incorporar hábitos alimenticios nuevos y
            organizar los horarios de las mismas. Se proyecta también una
            medición antropométrica para determinar la composición corporal y
            poder planificar sus próximos objetivos, ¡felicitaciones Agus y
            gracias por confiar en nosotros!!!
          </p>
        </div>
        <div className="px-4">
          <ImageSlider
            images={images}
            interval={3000}
            className={homeClassname}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-lg my-4">
        <div>
          <h2 className="text-lg font-customPlans text-center m-4 md:pb-10">
            Ana (70) ciudad de Mar del Plata
          </h2>
          <p className="texto-planes">
            Alumna personalizada con 2 días de gimnasio con trabajos de fuerza y
            postura adaptados a sus características individuales, más una
            práctica de Stretching para desarrollo de técnicas de estiramientos
            y prevención de lesiones. Felicitaciones Ana y gracias por tu
            confianza y buena predisposición!!!
          </p>
        </div>
        <div className="px-4">
          <ImageSlider
            images={images2}
            interval={3000}
            className={homeClassname}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
