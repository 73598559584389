import React, { useState, useEffect } from 'react';

function ImageSlider({ images, interval, className }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(sliderInterval); // Limpiar el intervalo cuando el componente se desmonte
  }, [images, interval]);

  return (
    <div>
      <img src={images[currentImageIndex]} alt="" className= {className}/>
    </div>
  );
}

export default ImageSlider;
