import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

const ServiceCard = ({ plan }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const navigate = useNavigate();

  return (
    <div className="bg-white rounded-lg p-6 shadow-lg flex flex-col items-center">
      <img
        src={process.env.PUBLIC_URL + plan.imageUrl}
        alt={plan.title}
        className="w-auto h-auto rounded-lg"
      />
      <h2 className="text-lg font-customPlans m-2">{plan.title}</h2>
      <p className="text-gray-600 font-customPlansSub mb-4">
        {plan.description}
      </p>
      <Button
        //className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        onPress={onOpen}
        color="primary"
        variant="shadow"
      >
        Quiero saber mas!
      </Button>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          <ModalHeader className="text-2xl font-bold mb-4">
            {plan.title}
          </ModalHeader>
          <ModalBody>
            <p className="text-gray-600 mx-8">{plan.text}</p>
          </ModalBody>
          <ModalFooter className="flex justify-center space-x-4">
            <Button color="secondary" variant="ghost" onPress={() => navigate("/contact")}>
              <MdOutlineEmail size={20} /> Contacto
            </Button>
            <a
              href="https://api.whatsapp.com/send?phone=542235281777"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="success" variant="ghost">
                <FaWhatsapp size={20} /> WhatsApp
              </Button>
            </a>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ServiceCard;
