import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {NextUIProvider} from "@nextui-org/react";
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import History from './pages/History';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Footer from './components/Footer';

function App() {
    return (
        <NextUIProvider>
            <div className="flex flex-col min-h-screen">
                <Router >
                    <Navbar />
                    <div className="container mx-auto mt-8 flex-grow">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/history" element={<History />} />
                            <Route path="/services" element={<Services />} />
                            <Route path="/contact" element={<Contact />} />
                        </Routes>
                    </div>
                    <Footer/>
                </Router>
            </div>
        </NextUIProvider>
    );
}

export default App;
