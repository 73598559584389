import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';

const validationSchema = Yup.object({
    from_email: Yup.string().email('Correo inválido').required('Requerido'),
    phone: Yup.string().required('Requerido'),
    subject: Yup.string().required('Requerido'),
    message: Yup.string().required('Requerido')
});

const Contact = () => {
    const email = "riosignacio312@outlook.com";

    const formik = useFormik({
        initialValues: {
            from_email: '',
            phone: '',
            subject: '',
            message: ''
        },
        validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            emailjs.send('service_r6n31cd', 'template_agt7njm', {
                to_email: email,
                from_email: values.from_email,
                phone: values.phone,
                subject: values.subject,
                message: values.message
            }, 'dDiHJe4P4Xy7IDTOm')
                .then(() => {
                    setSubmitting(false);
                    resetForm();
                    alert('Mensaje enviado!');
                }, error => {
                    alert('Hubo un error:', error.text);
                    setSubmitting(false);
                });
        }
    });

    return (
        <div className="flex flex-col items-center p-4 ">
            <h1 className="text-3xl font-custom mb-4">Contacto</h1>
            <form onSubmit={formik.handleSubmit} className="space-y-4 flex flex-col items-center w-96 m-4 sm:m-0">
                <input
                    type="email"
                    name="from_email"
                    placeholder="Correo"
                    className=" w-full p-2 border rounded-lg "
                    onChange={formik.handleChange}
                    value={formik.values.from_email}
                />
                <input
                    type="text"
                    name="phone"
                    placeholder="Celular"
                    className="w-full p-2 border rounded-lg"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                />
                <input
                    type="text"
                    name="subject"
                    placeholder="Nombre"
                    className="w-full p-2 border rounded-lg"
                    onChange={formik.handleChange}
                    value={formik.values.subject}
                />
                <textarea
                    name="message"
                    placeholder="Mensaje"
                    rows="4"
                    className="w-full p-2 border rounded-lg"
                    onChange={formik.handleChange}
                    value={formik.values.message}
                />
                <button type="submit" className="bg-green-700 px-10 rounded-xl shadow-md shadow-gray-600 hover:scale-110 duration-500 text-white p-2 ">Enviar</button>
            </form>
        </div>
    );
};

export default Contact;