const plansData = [
    {
        id: 1,
        title: 'PASE BRONCE',
        description: 'Armado de rutina personalizada',
        price: 29.99,
        imageUrl: '/images/planbronce.jpg',
        text: 'Armado de rutina personalizada (se actualiza a las 2 semanas)\n' +
            'Asesoramiento por videollamada para explicaciones, dudas, etc.\n' +
            'Acceso libre a la web para tips de hábitos saludables, notas relacionadas, encuestas, etc.',
    },{
        id: 2,
        title: 'PASE PLATA',
        description: 'Armado de plan de alimentación y seguimiento',
        price: 29.99,
        imageUrl: '/images/planplata.jpg',
        text: 'Los mismos beneficios del Plan Bronce más:\n' +
        'Armado de plan de alimentación y seguimiento (mensual) + videollamada para explicaciones, organización de comidas, hidratación, etc.',
    },
    {
        id: 3,
        title: 'PASE ORO',
        description: 'El más completo con todos los beneficios!',
        price: 29.99,
        imageUrl: '/images/planoro.jpg',
        text: 'Los mismos beneficios del Plan Plata más:\n' +
        'Una sesión de 45 minutos con la Psicóloga, relacionada con el desarrollo del proceso, objetivos, planificación, etc (mensual).\n' +
        'Una video clase semanal de 40 minutos de una disciplina a elección: Stretching, Yoga, Pilates mat, aero-localizada, automasajes, etc.',
    },
    {
        id: 4,
        title: 'PASE KIDS',
        description: 'Una forma de jugar y entrenar!',
        price: 29.99,
        imageUrl: '/images/ninos.jpeg',
        text: 'Sabemos que los nuevos tiempos, el acceso a la tecnología y los malos hábitos, han cambiado el desarrollo motor de los mas pequeños, se ha perdido el interés por el juego lo que trajo como consecuencia el crecimiento en la obesidad en los menores y una gran desmotivación.\n' +
            'Te proponemos un entrenamiento lúdico, divertido y fácil para poder realizar donde estes, el cual te permitirá incorporar nuevos hábitos saludables y mejorar tu condición física general y con ello la autoestima y desarrollo de la personalidad.',
    },
    {
        id: 5,
        title: 'PASE ADULTOS MAYORES',
        description: 'Un pase para mejorar sin importar la edad',
        price: 49.99,
        imageUrl: '/images/adultosmayores.jpeg',
        text: 'Si tenes más de 60 años y necesitas un entrenamiento acorde a tus posibilidades físicas, de tiempo, gustos y demás. Nuestro entrenamiento te permitirá mejorar tu condición física general y con ello tu estilo de vida.',
    },
    {
        id: 6,
        title: 'PASE DEPORTIVO',
        description: '¿Queres destacarte en tu deporte?',
        price: 79.99,
        imageUrl: '/images/plandepor.jpeg',
        text: 'Para aquellos que quieran hacerla diferencia en su deporte (futbol, rugby, handball, tenis, etc.).\n' +
            'Te ofrecemos un entrenamiento complementario para llegar al próximo nivel, totalmente personalizado y con seguimiento constante, testeos, mediciones y más…!',
    },
    {
        id: 7,
        title: 'PASE EMPRESAS',
        description: 'Pausa Activa',
        price: 79.99,
        imageUrl: '/images/planempresas.jpeg',
        text: 'Esta propuesta es para aquellas empresas que quieran incorporar en concepto de " PAUSA ACTIVA", el cual consiste en 2 o 3 estímulos semanales de 30 a 40 minutos máximo, para que sus empleados puedan realizar movilidad, estiramientos, ejercicios básicos y más, lo cual va a mejorar su condición física, optimiza el rendimiento en las horas restantes, mejora la relación entre grupos de trabajo y evita la falta productividad, por todas esas razones , este pase es la mejor opción para llevar a tu empresa al próximo nivel!',
    },
];

export default plansData;
