import React from 'react';
import ServiceCard from '../components/ServiceCard';
import plansData from '../data/plansData';

const Services = () => {
    return (
        <div class="flex flex-col items-center">
            <h1 className="text-3xl text-center font-custom mb-2">NUESTROS PLANES</h1>
            <p className="text-gray-500 font-another-custom">ELEGI EL PLAN PARA CUMPLIR TUS OBJETIVOS</p><br/><br/>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8 mx-4">
                {plansData.map((plan) => (
                    <ServiceCard
                        plan={plan}
                    />
                ))}
            </div>
        </div>
    );
};

export default Services;
