import React, { useEffect, useState } from "react";
import "../styles.css";
import { useNavigate } from "react-router-dom";
import ImageSlider from "../components/Slider";

const Home = () => {
  const planHome = "/images/planhome.jpg";
  const staff = "/images/staff.jpeg";

  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Ajusta este valor según tus necesidades
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    // Lógica para manejar el envío del formulario
    navigate("/services");
    window.scrollTo(0, 0);
  };

  const staffNav = (e) => {
    e.preventDefault();
    // Lógica para manejar el envío del formulario
    navigate("/history");
    window.scrollTo(0, 0);
  };

  const images = [
    process.env.PUBLIC_URL + "/images/planHome3.jpg",
    process.env.PUBLIC_URL + "/images/Entrenamiento-online.jpg",
    process.env.PUBLIC_URL + "/images/antro.jpg",
    process.env.PUBLIC_URL + "/images/nutricionista.jpeg",
    process.env.PUBLIC_URL + "/images/barraHome.jpg",
  ];

  const imagesCelu = [
    process.env.PUBLIC_URL + "/images/planHome3Celu.jpg",
    process.env.PUBLIC_URL + "/images/videocelu.jpg",
    process.env.PUBLIC_URL + "/images/antroCelu2.jpg",
    process.env.PUBLIC_URL + "/images/nutricelu.jpg",
    process.env.PUBLIC_URL + "/images/barraHomeCelu.jpg",
  ];

  const homeClassname = " w-full h-full rounded-lg transition-opacity ease-in-out duration-500";

  return (
    <div className="mx-8">
      <h1 className="titulo">
        ¡BIENVENIDOS A DGB TRAINING, tu rutina personal!
      </h1>

      <ImageSlider images={isMobile ? imagesCelu : images} interval={3000} className = {homeClassname}/>

      <h2 className="homeplanes">Conocé nuestros planes</h2>

      <div className="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-lg my-4">
        <div className="texto-planes">
          <p>
            ¡Bienvenidos a nuestros emocionantes planes de entrenamiento en
            línea! Estamos encantados de tener la oportunidad de acompañarte en
            este increíble viaje hacia una vida más saludable y activa. Nuestros
            planes de entrenamiento han sido cuidadosamente diseñados para
            brindarte resultados reales desde la comodidad de tu hogar,
            gimnasio, centro deportivo o en el lugar donde te encuentres. Ya sea
            que estés buscando aumentar tu nivel de energía, mejorar tu
            condición física o alcanzar tus objetivos de bienestar e incorporar
            nuevos hábitos saludables. Estamos aquí para apoyarte en cada paso
            del camino. Prepárate para descubrir un nuevo enfoque hacia el
            ejercicio, con rutinas personalizadas, instrucción experta y la
            flexibilidad de entrenar según tu propio horario. ¡Comencemos juntos
            este emocionante camino hacia una versión más fuerte y saludable de
            ti mismo!
            <a
              href="#"
              className="text-blue-500 underline font-medium ml-2 dark:text-blue-400 hover:text-blue-700 focus:text-blue-700"
              onClick={handleSubmit}
            >
              Leer más!
            </a>
          </p>
        </div>
        
        <img
          src={process.env.PUBLIC_URL + planHome}
          alt={planHome}
          className="md:w-64 md:h-64 rounded-lg mt-4 md:m-4"
        />
        
      </div>

      <h1 className="staff"> Nuestro Staff</h1>

      <div className="flex flex-col-reverse md:flex-row items-center bg-white rounded-lg shadow-lg my-4">
        <img
          src={process.env.PUBLIC_URL + staff}
          alt={staff}
          className="w-96 h-96 rounded-lg"
        />

        <div className="texto-planes mb-6 ">
          <p>
            ¡Descubre la sinergia perfecta para tu salud con nuestro equipo de
            expertos! Cada miembro de nuestro staff está aquí para catalizar tu
            transformación hacia una vida más plena y vigorosa. Nuestros planes
            de alimentación te guiarán a través de un mundo de opciones
            adaptadas a tus necesidades, para que cada comida sea un paso hacia
            tu mejor versión. El entrenador deportivo trae programas de
            ejercicios dinámicos y adaptativos, asegurando que cada movimiento
            esté alineado con tus metas personales. En el ámbito mental, nuestro
            psicólogo se enfoca en robustecer tu tenacidad y en cultivar una
            mentalidad de crecimiento, esencial para superar retos. Mientras
            tanto, el kinesiólogo es el guardián de tu integridad física,
            promoviendo técnicas que previenen lesiones y aceleran la
            recuperación. Con nosotros, obtendrás un acompañamiento constante y
            una estrategia personalizada que respeta tu ritmo y celebra tus
            avances. ¿Ya estás listo/a para empezar?
            <a
              href="#"
              className="text-blue-500 underline font-medium ml-2 dark:text-blue-400 hover:text-blue-700 focus:text-blue-700"
              onClick={staffNav}
            >
              Leer más!
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
